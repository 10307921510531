import React from "react"
import Layout from "../../components/global/Layout"

const Kontakt = () => {
  return (
    <Layout
      metaTitle="Kom i kontakt"
      metaDescription="Hvad end du har spørgsmål, ønsker at annoncere på Opholdsguide eller noget helt andet, skal du selvfølgelig være mere end velkommen til at kontakte os."
      slug="/information/kontakt/"
    >
      <div className="container my-xl">
        <section className="text-content">
          <h1>Kontakt</h1>
          <p>
            Du er altid meget velkommen til at kontakte os. Det kan ske via
            følgende oplysninger:
          </p>

          <p>Kontakt[a]opholdsguide.dk</p>

          <p>Administreret af:</p>
          <ul>
            <li>CVR: 37884804</li>
            <li>Opholdsguide</li>
            <li>Ved Stigbordene</li>
            <li>2450 – København SV</li>
          </ul>

          <p>
            Vi bestræber os på at besvare alle henvendelser inden for 24 timer.
          </p>
        </section>
      </div>
    </Layout>
  )
}

export default Kontakt
